// CSS imports
import "./ControlBar.css";

// React imports
import React, { useEffect, useRef, useState } from "react";

// Shared state
import { useSharedState } from "./SharedStateContext";

// Packages
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import axios from "axios";

// Control bar component
function ControlBar() {
  // Component state params
  const [question, setQuestion] = useState("");

  // Params from shared state
  const {
    answers,
    setAnswers,
    propertyId,
    setIsPINPopupOpen,
    jwtToken,
    setJwtToken,
    firstName,
    setFirstName,
    initialAction,
    setInitialAction,
  } = useSharedState();

  // Reference use to set focus on question input
  const questionInputRef = useRef(null);

  // Updates question state on question input change
  function handleQuestionChange(e) {
    setQuestion(e.target.value);
  }

  // On component mount
  useEffect(() => {
    // Set focus on question input
    questionInputRef.current.focus();

    // Get JWT token from cookie
    const storedJwtToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("jwt="))
      ?.split("=")[1];

    if (storedJwtToken) {
      setJwtToken(storedJwtToken);
    }

    if (initialAction === "login") {
      setIsPINPopupOpen(true);
    } else {
      setIsPINPopupOpen(false);
    }
  }, [initialAction]);

  // Executes question submit on Enter key press
  function handleQuestionKeyUp(e) {
    if (e.key === "Enter") {
      handleQuestionSubmitButton(e);
    }
  }

  // Provides answer to question
  async function handleQuestionSubmitButton() {
    // Answer contains both question and answer, initially answer is null
    const answer = { q: question, a: null, imageIds: [] };

    // Copy is needed to dynamically change last answer later once it's known
    const updatedAnswers = [...answers, answer];

    setAnswers(updatedAnswers);
    setQuestion("");

    var apiResponseData = null;

    try {
      // Call Ask API, authenticated or not
      /*let headers = {};

      if (jwtToken) {
        headers = {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        };
      }*/

      axios.defaults.withCredentials = true;

      const requestBody = {
        propertyId: propertyId,
        question: question,
      };

      const response = await axios(
        {
          url: "https://hama-chat.azurewebsites.net/api/ask",
          method: "POST",
          data: requestBody,
          withCredentials: true,
        }
        //{ headers },
      );

      apiResponseData = response.data;

      // Replace last answer with answer obtained from API
      if (updatedAnswers.length > 0) {
        const lastIndex = updatedAnswers.length - 1;

        const finalAnswers = [...updatedAnswers];

        finalAnswers[lastIndex] = {
          ...finalAnswers[lastIndex],
          a: apiResponseData.Answer,
          imageIds: apiResponseData.ImageIds,
        };

        setAnswers([...finalAnswers]);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        const answer = { q: null, a: `Your session expired.` };
        setAnswers([...answers, answer]);

        setJwtToken("");
        setFirstName(null);
      } else {
        const answer = {
          q: null,
          a: `Service is currently unavailable. Please try again later.`,
        };
        setAnswers([...answers, answer]);
      }
    }
  }

  // Output
  return (
    <Grid
      container
      spacing={2}
      padding={1}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={10}>
        <TextField
          id="outlined-basic"
          label="Question"
          variant="outlined"
          fullWidth="true"
          value={question}
          onChange={handleQuestionChange}
          onKeyUp={handleQuestionKeyUp}
          inputRef={questionInputRef}
        />
      </Grid>
      <Grid item xs={2}>
        <Tooltip title="Send">
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleQuestionSubmitButton}
            style={{ height: "100%" }}
          ></Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default ControlBar;
